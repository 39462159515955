import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MembersList from '../../../components/MembersList';
import PageTitle from '../../../components/PageTitle';
import { UserTypes } from './data';
import UserProfile from '../../../components/UserProfile/UserProfile';

const Users: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const { userType } = useParams<string>();
    const [title, setTitle] = useState<string>("");

    useEffect(() => {
        const userTypeObj = UserTypes.find((userTypeObj) => userTypeObj.type === userType);
        if (userTypeObj) {
            setTitle(userTypeObj.title);
        } else {
            setTitle("Unknown User Type");
        }
    }, [userType]);

    return (
        <React.Fragment>
            <PageTitle
                breadCrumbItems={[
                    { label: `Users`, path: `/apps/users/${userType}` },
                    { label: `${title}`, path: `/users/${userType}`, active: true },
                ]}
                title="Users"
            />
            <div className="row">
                <div className={userType === "all-miners" ? "col-md-5" : "col-md-3"}>
                    <MembersList onSelected={setEmail} title={title} />
                </div>
                <div className={userType === "all-miners" ? "col-sm-12 col-md-7" : "col-sm-12 col-md-9"}>
                    <UserProfile email={email} userId='' />
                </div>
            </div>
        </React.Fragment>
    );
};

export default Users;

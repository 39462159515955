import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import QuestionCard from "../../../components/QuestionCard";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import PageTitle from "../../../components/PageTitle";
import AddNewQuestion from "../../../components/AddNewQuestion";
import Pagination from "../../../components/Pagination";
import { QuestionType, questions as initialQuestions } from "./data";
import { postQuestion, deleteQuestion, updateQuestion } from "../../../helpers";
import SuccessModal from "../../../components/SuccessModal";

const QuestionPage: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [newQuestionModal, setNewQuestionModal] = useState<boolean>(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [questionToDelete, setQuestionToDelete] = useState<QuestionType | null>(null); // State to hold the question to delete
    const [newQuestionDetails, setNewQuestionDetails] = useState<QuestionType | null>(null);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [displayedQuestions, setDisplayedQuestions] = useState<QuestionType[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [pageSize, setPageSize] = useState<number>(5);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [questions, setQuestions] = useState<QuestionType[]>(initialQuestions);
    const schemaResolver = yupResolver(
        yup.object().shape({
            option1: yup.string().required(),
            option2: yup.string().required(),
            option3: yup.string().required(),
            option4: yup.string().required(),
            answer: yup.string().required(),
            question: yup.string().required(),
        })
    );

    const methods = useForm({ resolver: schemaResolver });
    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: { errors },
    } = methods;

    useEffect(() => {
        setTimeout(() => {
            setQuestions(initialQuestions);
        }
        , 2000);
    }
    , []);

    useEffect(() => {
        const filteredQuestions = questions.filter((question) =>
            question.question.toLowerCase().includes(searchQuery.toLowerCase())
        );
        const startIndex = pageIndex * pageSize;
        const endIndex = startIndex + pageSize;
        const newDisplayedQuestions = filteredQuestions.slice(startIndex, endIndex);
        setDisplayedQuestions(newDisplayedQuestions);
        setShowSuccessModal(false);
    }, [pageIndex, pageSize, questions, searchQuery]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
        setPageIndex(0);
    };

    const handlePageSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(e.target.value) > 1 ? parseInt(e.target.value) : 1)
    }

    const toggleNewQuestionModal = () => {
        setNewQuestionModal((prevState) => !prevState);
    };

    const addNewQuestion = async (data: QuestionType) => {
        const optionToAnsConverter = (ques: QuestionType) => {
            return {
                ...ques,
                answer: ques.answer === "option1" ? ques.option1 : ques.answer === "option2" ? ques.option2 : ques.answer === "option3" ? ques.option3 : ques.option4
            };
        };
        try {
            const response = await postQuestion(optionToAnsConverter(data));
            if (response.data.statusCode === 201) {
                setShowSuccessModal(true);
                handleModalClose();
                setTimeout(() => {
                    window.location.reload();
                }, 5000);
            }
        } catch (error) {
            alert("Error adding question");
            console.error("Error adding question:", error);
        }
    };

    const handleModalClose = () => {
        reset();
        setNewQuestionModal(false);
    };

    const handleDeleteQuestion = (question: any) => {
        setQuestionToDelete(question); // Set the question to delete in the state
        setShowConfirmationModal(true);
    };

    const hideModal = () => {
        setShowConfirmationModal(false);
    }

    const confirmDelete = async () => {
        if (!questionToDelete) return; // Check if there is a question to delete
        try {
            const response = await deleteQuestion(questionToDelete);
            if (response.data.statusCode === 205) {
                const updatedQuestions = questions.filter((q) => q !== questionToDelete);
                setDisplayedQuestions(updatedQuestions);
                hideModal();
                setShowSuccessModal(true);
            }
        } catch (error) {
            alert("Error deleting question");
            console.error("Error deleting question:", error);
        }
    };

    const renderConfirmationModal = () => {
        return (
            <ConfirmationModal
                message="Are you sure you want to delete this question?"
                show={showConfirmationModal}
                onHide={hideModal}
                onConfirm={confirmDelete}
            />
        );
    };

    
    const handleUpdateQuestion = async(data: any) => {
        setIsLoading(true);
        const values: QuestionType = {
            option1: data.option1,
            option2: data.option2,
            option3: data.option3,
            option4: data.option4,
            answer: data.answer,
            question: data.question,
        };
        const ques = {
            id: data.id,
            body: values,
        }
        try {
            const response = await updateQuestion(ques);
            if(response.data.statusCode === 202) {
                setShowSuccessModal(true);
                setIsLoading(false);
            }
        } catch (error) {
            alert("Error updating question");
            console.error("Error deleting question:", error);
            setIsLoading(false);
        }
    };

    const renderNewQuestionModal = () => {
        return (
            <AddNewQuestion
                newQuestionModal={newQuestionModal}
                toggleNewQuestionModal={toggleNewQuestionModal}
                handleNewQuestion={handleSubmit(addNewQuestion)}
                handleSubmit={handleSubmit}
                newQuestionDetails={newQuestionDetails}
                register={register}
                errors={errors}
                control={control}
            />
        );
    };

    return (
        <React.Fragment>
            <PageTitle
                breadCrumbItems={[
                    { label: "Question Page", path: "/apps/questions" },
                ]}
                title="Question Page"
            />
            <Row className="d-flex justify-content-between">
                <Col sm={3}>
                    <Button variant="primary" className="mb-3" onClick={toggleNewQuestionModal}>
                        <i className="uil uil-plus me-1"></i>Add New
                    </Button>
                </Col>
                <Col sm={5}>
                    <Form.Group as={Row}>
                        <Form.Label column sm={3}>Page Size:</Form.Label>
                        <Col sm={3}>
                            <Form.Control
                                type="number"
                                value={pageSize}
                                onChange={handlePageSizeChange}
                            />
                        </Col>
                    </Form.Group>
                </Col>

                <Col sm={3}>
                    <Form.Control
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </Col>
            </Row>
            {displayedQuestions.map((question: QuestionType, index: number) => (
                <QuestionCard
                    key={index}
                    question={question}
                    onDelete={handleDeleteQuestion}
                    onUpdate={handleUpdateQuestion}
                    isLoading={isLoading}
                />
            ))}
            {questions.length === 0 ? <div>Loading...</div> : null}
            <Pagination
                tableProps={{
                    pageCount: Math.ceil(questions.length / pageSize),
                    state: { pageIndex },
                    gotoPage: (page: number) => {
                        setPageIndex(page);
                    },
                    pageOptions: Array.from({ length: Math.ceil(questions.length / pageSize) }).map((_, i) => i),
                }}
                sizePerPageList={[]}
            />
            {newQuestionModal && renderNewQuestionModal()}
            {showConfirmationModal && renderConfirmationModal()}
            {showSuccessModal && <SuccessModal show={true} message="Question added successfully" onHide={()=> setShowSuccessModal(false)} />}
            {showSuccessModal && <SuccessModal show={true} message="Questions updated successfully" onHide={()=> setShowSuccessModal(false)} />}
        </React.Fragment>
    );
};

export default QuestionPage;

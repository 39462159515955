import React, { useState, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';

interface SettingCardProps {
    title: string;
    data: {
        label: string;
        value: number;
        key: string;
    }[];
    onDataChange: (newData: any) => void;
}

const SettingCard: React.FC<SettingCardProps> = ({ title, data, onDataChange }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState<any[]>([]);

    useEffect(() => {
        setEditedData(data);
    }, [data]);

    const onEdit = () => {
        setIsEditing(true);
    };

    const onCancel = () => {
        setIsEditing(false);
        setEditedData(data);
    };

    const onSave = async () => {
        const mappedData: { [key: string]: number } = {};
        editedData.forEach((item) => {
            mappedData[item.key] = parseFloat(item.value); // Parse value to float
        });
        onDataChange(mappedData);
        setIsEditing(false);
    };


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = e.target;
        const newData = [...editedData];
        newData[index].value = value; // Set value directly
        setEditedData(newData);
    };

    return (
        <Card>
            <Card.Body>
                <div className="d-flex justify-content-between my-2">
                    <h5 className="header-title">{title}</h5>
                    <div>
                        {isEditing ? (
                            <>
                                <Button variant="secondary" className="mx-2" onClick={onCancel}>Cancel</Button>
                                <Button variant="primary" onClick={onSave}>Save</Button>
                            </>
                        ) : (
                            <Button variant="primary" onClick={onEdit}>Edit</Button>
                        )}
                    </div>
                </div>
                <div className="row">
                    {isEditing ? (
                        editedData.map((item, index) => (
                            <div key={index} className="my-2 col-md-6">
                                <label htmlFor={item.key}>{item.label}</label>
                                <input
                                    type="text" // Change input type to "text"
                                    name={item.label}
                                    value={item.value}
                                    onChange={(e) => handleChange(e, index)}
                                    className="form-control"
                                    id={item.key}
                                    placeholder={`Enter ${item.label}`}
                                />
                            </div>
                        ))
                    ) : (
                        data.map((item, index) => (
                            <div key={index} className="mb-3 col-md-6">
                                <label>{item.label}</label>
                                <p>{item.value}</p>
                            </div>
                        ))
                    )}
                </div>
            </Card.Body>
        </Card>
    );
};

export default SettingCard;

import { APICore } from "./apiCore";
import { QuestionType } from "../../pages/apps/Questions/data";
const api = new APICore();

function getQuestion() {
    const baseUrl = "/admin/question/all";
    const data: any = api.get(`${baseUrl}`, {});
    return data;
}

function postQuestion(question: QuestionType) {
    const baseUrl = "/admin/question/create";
    const body = question;
    const data = api.create(`${baseUrl}`, body)
    return data;
}

function deleteQuestion(question: any) {
    const baseUrl = "/admin/question/delete";
    const param = { questionId: question.id }
    const data = api.delete(`${baseUrl}`, param);
    return data;
}

function updateQuestion(question: any) {
    const baseUrl = "/admin/question/update";
    const params = { questionId: question.id };
    const body = question.body;
    const data = api.updatePatch(`${baseUrl}`, body, params);
    return data;
}
export { getQuestion, postQuestion, deleteQuestion, updateQuestion}
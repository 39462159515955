import React, { useEffect } from "react";
import { Card, Button, Form, Col, Row } from "react-bootstrap";
import { AppType } from "../pages/apps/Settings/data";

interface AppVersionProps {
    app: AppType;
    onSave: (data: AppType) => void;
}
/**
export interface AppType {
    version: number;
    maintenanceMode: boolean;
    updateRequired: boolean;
    updateMessage?: string;
    minSupportedVersionCode: number;
    maintenanceMessage?: string;
    noticeMode: boolean;
    noticeMessage?: string;
  }
  
 */
const AppVersion: React.FC<AppVersionProps> = ({ app, onSave }) => {
    const [editMode, setEditMode] = React.useState(false);
    const [formData, setFormData] = React.useState(app);
    const [editedData, setEditedData] = React.useState<AppType>(app);

    useEffect(() => {
        setEditedData(app);
    }, [app]);

    const onCancel = () => {
        setEditMode(false);
        setEditedData(formData);
    }
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value} = e.target;
        const newValue = value;
        setEditedData({ ...editedData, [name]: newValue });
        // console.log('Edited data:', editedData);
    };

    const handleSave = () => {
        setEditMode(false);
        onSave(editedData);
    };

    return (
        <Card className="mb-3 app-version-card">
            <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <Card.Title className="mb-3 header-title">App Status</Card.Title>
                    <div>
                        {editMode ? (
                            <>
                                <Button variant="success" onClick={handleSave} className="me-2">
                                    Save
                                </Button>
                                <Button variant="outline-danger" onClick={
                                    onCancel
                                }>
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            <Button variant="primary" onClick={() => setEditMode(true)}>
                                Edit
                            </Button>
                        )}
                    </div>
                </div>
                <Form as={Row}>
                    <Form.Group as={Col} md={3} controlId="appVersion" className="mb-3">
                        <Form.Label>App Version</Form.Label>
                        <Form.Control
                            type="text"
                            name="version"
                            value={editedData.version}
                            onChange={handleInputChange}
                            disabled={!editMode}
                            className={editMode ? "border border-primary fs-2 text-wrap" : "border-0 fs-2 text-wrap"}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="minSupportedVersionCode" className="mb-3">
                        <Form.Label>Min Supported Version Code</Form.Label>
                        <Form.Control
                            type="number"
                            name="minSupportedVersionCode"
                            value={editedData.minSupportedVersionCode}
                            onChange={handleInputChange}
                            disabled={!editMode}
                            className={editMode ? "border border-primary text-wrap" : "border-0 text-wrap"}
                        />
                    </Form.Group>
                    <hr />
                    <Form.Group as={Col} md={3} controlId="maintenanceMode" className="mb-2 d-flex">
                        <Form.Check
                            type="switch"
                            label="Maintenance Mode"
                            id="maintenanceMode"
                            className="align-self-center"
                            checked={editedData.maintenanceMode}
                            onChange={
                                (e) => {
                                    setEditedData({ ...editedData, maintenanceMode: !editedData.maintenanceMode });
                                }
                            }
                            disabled={!editMode}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="maintenanceMessage" className="mb-3">
                        <Form.Label>Maintenance Message</Form.Label>
                        <Form.Control
                            type="text"
                            name="maintenanceMessage"
                            value={editedData.maintenanceMessage || "N/A"}
                            onChange={handleInputChange}
                            disabled={!editMode}
                            className={editMode ? "border border-primary  text-wrap" : "border-0  text-wrap"}
                        />
                    </Form.Group>
                    <hr />
                    <Form.Group as={Col} md={3} controlId="updateRequired" className="mb-2 d-flex">
                        <Form.Check
                            type="switch"
                            label="Update Required"
                            id="updateRequired"
                            className="align-self-center"
                            checked={editedData.updateRequired}
                            onChange={
                                (e) => {
                                    setEditedData({ ...editedData, updateRequired: !editedData.updateRequired });
                                }
                            }
                            disabled={!editMode}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="updateMessage" className="mb-3">
                        <Form.Label>Update Message</Form.Label>
                        <Form.Control
                            type="text"
                            name="updateMessage"
                            value={editedData.updateMessage || "N/A"}
                            onChange={handleInputChange}
                            disabled={!editMode}
                            className={editMode ? "border border-primary text-wrap" : "border-0 text-wrap"}
                        />
                    </Form.Group>
                    <hr />
                    <Form.Group as={Col} md={3} controlId="noticeMode" className="mb-2 d-flex">
                        <Form.Check
                            type="switch"
                            label="Notice Mode"
                            id="noticeMode"
                            className="align-self-center"
                            checked={editedData.noticeMode}
                            onChange={
                                (e) => {
                                    setEditedData({ ...editedData, noticeMode: !editedData.noticeMode });
                                }
                            }
                            disabled={!editMode}
                        />
                    </Form.Group>
                    <Form.Group as={Col} controlId="noticeMessage" className="mb-3">
                        <Form.Label>Notice Message</Form.Label>
                        <Form.Control
                            type="text"
                            name="noticeMessage"
                            value={editedData.noticeMessage || "N/A"}
                            onChange={handleInputChange}
                            disabled={!editMode}
                            className={editMode ? "border border-primary text-wrap" : "border-0 text-wrap"}
                        />
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
};

export default AppVersion;

import { APICore } from "./apiCore";
const api = new APICore();

export async function getDashboardData() {
    const totalMinedTokensApi = "/admin/mine/tokens/lifeTime";
    const totalShidRewarrdedApi = "/admin/token/shib/totalrewarded";
    const totalActiveMinersApi = "/admin/users/activeMiners";
    const totalInActiveMinersApi = "/admin/users/inActiveMiners";
    const totalUsersApi = "/admin/users/total";
    const totalNewACITokensApi = "/admin/newTokensMined";
    const totalShibHoldersApi = "/admin/users/holdingShibTokens";
    const totalReferralsApi = "/admin/users/totalReferrals";
    const totalBannedMinersApi = "/admin/users/totalBannedMiners";

    const totalMinedTokensResponse = await api.get(`${totalMinedTokensApi}`, {});
    const totalMinedToken = totalMinedTokensResponse.data.result.lifeTimeTokens.toFixed(2);
    const totalShidRewarrdedResponse = await api.get(`${totalShidRewarrdedApi}`, {});
    const totalShidRewarrded = totalShidRewarrdedResponse.data.result.totalShibTokensRewarded.toFixed(2);
    const totalActiveMinersResponse = await api.get(`${totalActiveMinersApi}`, {});
    const totalActiveMiners = totalActiveMinersResponse.data.result.activeMiners;
    const totalInActiveMinersResponse = await api.get(`${totalInActiveMinersApi}`, {});
    const totalInActiveMiners = totalInActiveMinersResponse.data.result.inActiveMiners;
    const totalUsersResponse = await api.get(`${totalUsersApi}`, {});
    const totalUsers = totalUsersResponse.data.result.totalUsers;
    const totalNewACITokensResponse = await api.get(`${totalNewACITokensApi}`, {});
    const totalNewACITokens = totalNewACITokensResponse.data.result.newAciTokens.toFixed(2);
    const totalShibHoldersResponse = await api.get(`${totalShibHoldersApi}`, {});
    const totalShibHolders = totalShibHoldersResponse.data.result.total;
    const totalReferralsResponse = await api.get(`${totalReferralsApi}`, {});
    const totalReferrals = totalReferralsResponse.data.result.totalReferrals;
    const totalBannedMinersResponse = await api.get(`${totalBannedMinersApi}`, {});
    const totalBannedMiners = totalBannedMinersResponse.data.result;
    
    return {
        totalMinedToken,
        totalShidRewarrded,
        totalActiveMiners,
        totalInActiveMiners,
        totalUsers,
        totalNewACITokens,
        totalShibHolders,
        totalReferrals,
        totalBannedMiners
    }
}
import { APICore } from "./apiCore";

const api = new APICore();

// account
function login(body: { email: string; password: string }) {
  const baseUrl = "/auth/login";
  const data = api.create(`${baseUrl}`, body);
  return data;
}

function getUser(params: { email: string;}) {
  const baseUrl = "/admin/users/findUser";
  return api.get(`${baseUrl}`, params)
}
function logout() {
  const baseUrl = "/user/logout";
  return api.get(`${baseUrl}`, {});
}

function signup(params: { fullname: string; email: string; password: string }) {
  const baseUrl = "/register/";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, getUser };

import axios from "axios";

import config from "../../config";

// Set the content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = config.API_URL;

// Intercepting to capture errors
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Any status codes that fall outside the range of 2xx cause this function to trigger
    let message;

    if (error && error.response && error.response.status === 404) {
      window.location.href = '/not-found';
    } else if (error && error.response && error.response.status === 403) {
      // window.location.href = "/access-denied";
    } else {
      switch (error.response.status) {
        case 401:
          message = "Invalid credentials";
          break;
        case 403:
          message = "Access Forbidden";
          break;
        case 404:
          message = "Sorry! the data you are looking for could not be found";
          break;
        default: {
          message =
            error.response && error.response.data
              ? error.response.data["message"]
              : error.message || error;
        }
      }
      return Promise.reject(message);
    }
  }
);

const AUTH_SESSION_KEY = "orbaic_admin";

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

const getUserFromCookie = () => {
  const user = sessionStorage.getItem(AUTH_SESSION_KEY);
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};
function beforRequest() {
  const user = getUserFromCookie();
  if (user && user.token) {
    setAuthorization(user.token);
  }

}
function cleanSessions() {
  sessionStorage.clear();
}
class APICore {
  /**
   * Fetches data from the given URL
   */
  get = (url: string, params: any) => {
    let response;
    beforRequest();
    if (params) {
      const queryString = params
        ? Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }
    
    return response;
  };

  getFile = (url: string, params: any) => {
    let response;
    if (params) {
      const queryString = params
        ? Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, { responseType: "blob" });
    } else {
      response = axios.get(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = (urls: string, params: any) => {
    const reqs = [];
    const queryString = params
      ? Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&")
      : "";

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
   * Post the given data to the URL
   */
  create = async (url: string, data: any) => {
    try {
      beforRequest();
      // console.log("data", data);
      const response = await axios.post(url, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   * change pass api
   */
  create2 = async (url: string, params: any) => {
    let response;
    beforRequest();
    if (params) {
      const queryString = params
        ? Object.keys(params)
          .map((key) => key + '=' + params[key])
          .join('&')
        : "";
      response = await axios.post(`${url}?${queryString}`, params);
    } else {
      response = await axios.post(`${url}`, params);
    }
    return response;
  }
  /**
   * Updates patch data
   */
  updatePatch = (url: string, data: any, params: any) => {
    let response;
    beforRequest();
    if (params) {
      const queryString = params
        ? Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
        : "";
      response = axios.patch(`${url}?${queryString}`, data);
    } else {
      // console.log(url, data)
      response = axios.patch(`${url}`, data);
    }
    return response;
  };

  /**
   * Updates data
   */
  update = (url: string, data: any) => {
    return axios.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = (url: string, params: any) => {
    let response;
    beforRequest();
    if (params) {
      const queryString = params
        ? Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
        : "";
      response = axios.delete(`${url}?${queryString}`);
    } else {
      response = axios.delete(`${url}`);
    }
    return response;
  }

  /**
   * Post the given data to the URL with a file
   */
  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, formData, config);
  };

  /**
   * Post the given data to the URL with a file
   */
  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.patch(url, formData, config);
  };

  isUserAuthenticated = () => {
    const user = this.getLoggedInUser();

    if (!user) {
      return false;
    }
    // Since you are using Bearer token, the presence of token in the session indicates authentication
    return true;
  };

  setLoggedInUser = (session: any) => {
    if (session)
      sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
    else {
      // alert("logout")
      sessionStorage.removeItem(AUTH_SESSION_KEY);
    }
  };

  /**
   * Returns the logged-in user
   */
  getLoggedInUser = () => {
    return getUserFromCookie();
  };

  setUserInSession = (modifiedUser: any) => {
    let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
    if (userInfo) {
      const { token, user } = JSON.parse(userInfo);
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };
}

// Check if token available in session
let user = getUserFromCookie();
if (user) {
  const { token } = user;
  if (token) {
    setAuthorization(token);
  }
}

export { APICore, setAuthorization };

import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Form, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import UserProfile from '../../../components/UserProfile/UserProfile';
import PageTitle from '../../../components/PageTitle';
import { useSearchParams, useParams } from 'react-router-dom';

const SearchUser: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const [userId, setUserId] = useState<string>("");
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [searchType, setSearchType] = useState<"email" | "userId">("email");
    const [searchParams, setSearchParams] = useSearchParams();
    const { userEmail } = useParams<{ userEmail: string }>();

    useEffect(() => {
        const emailParam = searchParams.get("userEmail");
        if (emailParam) {
            setSearchQuery(emailParam);
            setEmail(emailParam);
        }
        const userIdParam = searchParams.get("userId");
        if (userIdParam) {
            setSearchQuery(userIdParam);
            setUserId(userIdParam);
        }
    }, [searchParams]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchTypeChange = (type: "email" | "userId") => {
        setSearchType(type);
    };

    const handleClicked = () => {
        setEmail(searchQuery);
        setSearchParams(searchType === "email" ? { userEmail: searchQuery } : { userId: searchQuery });
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleClicked();
        }
    };

    return (
        <React.Fragment>
            <PageTitle
                breadCrumbItems={[
                    { label: `Users`, path: `/apps/users/search` },
                    { label: `Search`, path: `/users/search`, active: true },
                ]}
                title="Search User"
            />
            <Row className='d-flex justify-content-center'>
                <Col sm={4} className=''>
                    <InputGroup>
                        <DropdownButton
                            variant="primary"
                            title={searchType === "email" ? "Search by Email" : "Search by User ID"}
                            
                        >
                            <Dropdown.Item onClick={() => handleSearchTypeChange("email")}>Email</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleSearchTypeChange("userId")}>User ID</Dropdown.Item>
                        </DropdownButton>
                        <Form.Control
                            type="text"
                            placeholder={`Enter ${searchType === "email" ? "Email" : "User ID"}`}
                            value={searchQuery}
                            onChange={handleSearchChange}
                            onKeyDown={handleKeyPress}
                        />
                        <Button variant="success" onClick={handleClicked}>Search</Button>
                    </InputGroup>
                </Col>
            </Row>
            <Row className='mt-3'>
                <UserProfile email={email} userId={userId} />
            </Row>
        </React.Fragment>
    );
};

export default SearchUser;

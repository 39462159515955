import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { UseFormHandleSubmit, FieldErrors, Control } from "react-hook-form";
import FormInput from "./FormInput";

interface ResetPassModalProps {
    resetPassModal: boolean;
    toggleResetPassModal: () => void;
    handleResetPassword: (values: any) => void;
    handleSubmit: UseFormHandleSubmit<any>;
    errors: FieldErrors;
    control?: Control<any>;
    register?: any;
}

const ResetPassModal: React.FC<ResetPassModalProps> = ({
    resetPassModal,
    toggleResetPassModal,
    handleResetPassword,
    handleSubmit,
    register,
    errors,
    control,
}) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    useEffect(() => {
        const isPasswordValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(password);
        const isConfirmPasswordValid = password === confirmPassword;

        setIsButtonDisabled(!(isPasswordValid && isConfirmPasswordValid));
    }, [password, confirmPassword]);

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
    };

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        navigator.clipboard.writeText(password).then(() => {
            handleSubmit(handleResetPassword)();
        });
    };

    return (
        <Modal show={resetPassModal} onHide={toggleResetPassModal} size="lg" centered>
            <Modal.Header closeButton>
                <h4 className="modal-title">Reset Password</h4>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleFormSubmit} className="px-2">
                    <Row className="mb-3">
                        <Col>
                            <FormInput
                                name="password"
                                label="New Password"
                                placeholder="Enter new password"
                                type="password"
                                register={register}
                                errors={errors}
                                control={control}
                                onChange={handlePasswordChange}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <FormInput
                                name="confirmPassword"
                                label="Confirm Password"
                                placeholder="Re-enter new password"
                                type="password"
                                register={register}
                                errors={errors}
                                control={control}
                                onChange={handleConfirmPasswordChange}
                            />
                        </Col>
                    </Row>
                    {/* List of requirements with changing color with regex match */}
                    <Row className="mb-3">
                        <Col>
                            <ul className="list-unstyled">
                                <li className={/(?=.*[a-z])/.test(password) ? "text-success" : "text-danger"}>
                                    At least one lowercase letter
                                </li>
                                <li className={/(?=.*[A-Z])/.test(password) ? "text-success" : "text-danger"}>
                                    At least one uppercase letter
                                </li>
                                <li className={/(?=.*\d)/.test(password) ? "text-success" : "text-danger"}>
                                    At least one number
                                </li>
                                <li className={/.{8,}/.test(password) ? "text-success" : "text-danger"}>
                                    Minimum 8 characters
                                </li>
                                <li className={password && password === confirmPassword ? "text-success" : "text-danger"}>
                                    Passwords match
                                </li>
                            </ul>
                        </Col>
                    </Row>

                    <div className="text-end">
                        <Button variant="light" className="me-1" onClick={toggleResetPassModal}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" disabled={isButtonDisabled}>
                            Copy and Submit
                        </Button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default ResetPassModal;

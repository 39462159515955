import React, { useState, useEffect } from "react";
import { Card, Button, Form, Row, Col } from "react-bootstrap";

interface Props {
    question: QuestionType;
    onDelete: (question: QuestionType) => void;
    onUpdate: (question: any) => void;
    isLoading?: boolean;
}

interface QuestionType {
    question: string;
    option1: string;
    option2: string;
    option3: string;
    option4: string;
    answer: string;
}

const QuestionCard: React.FC<Props> = ({ question, onDelete, onUpdate, isLoading }) => {
    const [editedQuestion, setEditedQuestion] = useState<any>({ ...question });
    const [isEditing, setIsEditing] = useState(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditedQuestion((prevQuestion: any) => ({
            ...prevQuestion,
            [name]: value,
        }));
    };

    useEffect(() => {
        setEditedQuestion({ ...question });
    }, [question]);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        setIsEditing(false);
        onUpdate(editedQuestion);
    };

    const handleDeleteClick = () => {
        onDelete(editedQuestion);
        // You can add logic to delete the question here
    };

    const matchCorrectAnswer = () => {
        switch (editedQuestion.answer) {
            case editedQuestion.option1:
                return editedQuestion.option1;
            case editedQuestion.option2:
                return editedQuestion.option2;
            case editedQuestion.option3:
                return editedQuestion.option3;
            case editedQuestion.option4:
                return editedQuestion.option4;
            default:
                return "";
        }
    };

    return (
        <Card className="mb-3">
            <Card.Body>
                <Form>
                    <Form.Group as={Row} className="mb-3" controlId="question">
                        <Form.Label column sm={2} className="fs-2">Question:</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="textarea"
                                name="question"
                                value={editedQuestion.question}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                className={isEditing ? "border border-primary fs-2 text-wrap" : "border-0 fs-2 text-wrap"}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="option1">
                        <Form.Label column sm={2}>Option 1:</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                name="option1"
                                value={editedQuestion.option1}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                className={isEditing ? "border border-primary" : "border-0"}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="option2">
                        <Form.Label column sm={2}>Option 2:</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                name="option2"
                                value={editedQuestion.option2}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                className={isEditing ? "border border-primary" : "border-0"}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="option3">
                        <Form.Label column sm={2}>Option 3:</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                name="option3"
                                value={editedQuestion.option3}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                className={isEditing ? "border border-primary" : "border-0"}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-1" controlId="option4">
                        <Form.Label column sm={2}>Option 4:</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                type="text"
                                name="option4"
                                value={editedQuestion.option4}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                className={isEditing ? "border border-primary" : "border-0"}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2" controlId="correctAnswer">
                        <Form.Label column sm={2}>Correct Answer:</Form.Label>
                        <Col sm={10}>
                            <Form.Control
                                as="select"
                                name="answer"
                                value={matchCorrectAnswer()}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                                className={isEditing ? "border border-primary" : "border-0"}
                            >
                                <option value={editedQuestion.option1}>{editedQuestion.option1}</option>
                                <option value={editedQuestion.option2}>{editedQuestion.option2}</option>
                                <option value={editedQuestion.option3}>{editedQuestion.option3}</option>
                                <option value={editedQuestion.option4}>{editedQuestion.option4}</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Form>
                {isEditing ? (
                    <Button 
                    variant="success" 
                    className="me-2" 
                    onClick={handleSaveClick}
                    disabled={
                        isLoading === true
                    }
                    >
                        Save
                    </Button>
                ) : (
                    <Button variant="primary" className="me-2" onClick={handleEditClick}>
                        Edit
                    </Button>
                )}
                <Button variant="danger" className="me-2" onClick={handleDeleteClick}>Delete</Button>
            </Card.Body>
        </Card>
    );
};

export default QuestionCard;

import React, { useState, useEffect } from "react";
import { Card, Button, Form, Dropdown } from "react-bootstrap";
import { userListType } from "../pages/apps/Users/data";
import { UserTypes } from "../pages/apps/Users/data";
import { getUserList, getMemberCount, sortUserList } from "../helpers";
import Spinner from "./Spinner";
import FeatherIcon from "feather-icons-react";

export interface MembersTypes {
  id: number;
  name: string;
  avatar: string;
  point: number;
}

interface MembersListProps {
  title: string;
  onSelected: (email: string) => void;
}

const sortOptions = [
  { key: 'shibTokens', label: 'SHIB Tokens' },
  { key: 'mineTokens', label: 'Mine Tokens' },
  { key: 'referralTokens', label: 'Referral Tokens' },
  { key: 'referralBoostTokens', label: 'Referral Boost Tokens' },
  { key: 'quizRewardTokens', label: 'Quiz Reward Tokens' },
  { key: 'createdAt', label: 'Created At' },
  { key: 'lastLoginAt', label: 'Last Login At' }
];

const MembersList = ({ title, onSelected }: MembersListProps) => {
  const [members, setMembers] = useState<userListType[]>([]);
  const [totalMembers, setTotalMembers] = useState<number | null>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [type, setType] = useState<string>("");
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);
  const [activeSort, setActiveSort] = useState<string>('shibTokens');

  const handleClick = (email: string) => {
    onSelected(email);
    setSelectedEmail(email);
  };

  useEffect(() => {
    const userTypeObj = UserTypes.find((userTypeObj) => userTypeObj.title === title);
    if (userTypeObj) {
      setType(userTypeObj.type);
      setMembers([]);
      const fetchUserList = async () => {
        setTotalMembers(null);
        try {
          if (userTypeObj.countApi !== "") {
            const response = await getMemberCount(userTypeObj.countApi);
            if (userTypeObj.type === "active-miners")
              setTotalMembers(response.data.result.activeMiners);
            else if (userTypeObj.type === "inactive-miners")
              setTotalMembers(response.data.result.inActiveMiners);
            else if (userTypeObj.type === "all-miners")
              setTotalMembers(response.data.result.totalUsers);
          }

          if (userTypeObj.api === `all`) {
            const response = await sortUserList(pageNumber, pageSize,
              activeSort === 'shibTokens',
              activeSort === 'mineTokens',
              activeSort === 'referralTokens',
              activeSort === 'referralBoostTokens',
              activeSort === 'quizRewardTokens',
              activeSort === 'createdAt',
              activeSort === 'lastLoginAt');
            setMembers(response.data.result.users);
          } else {
            const response = await getUserList(userTypeObj.api, pageNumber, pageSize);
            if (userTypeObj.api === `activeMiningUsers`)
              setMembers(response.data.result.users.sort(() => 0.5 - Math.random()).slice(0, 100));
            else if (userTypeObj.api === `bannedUsers`)
              setMembers(response.data.result.bannedUsers);
            else
              setMembers(response.data.result.users);
            if (userTypeObj.api === `getNewUsers`) {
              setTotalMembers(response?.data.result.users.length);
              setMembers(response?.data.result.users.reverse());
            }
          }

        } catch (error) {
          console.error("Error fetching user list:", error);
        }
      };
      fetchUserList();
    } else {
      console.error("User type not found for title:", title);
    }
  }, [title, pageNumber, pageSize, activeSort]);

  const convertToTime = (time: number) => {
    const date = new Date(time);
    const formattedDate = date.toLocaleString();
    return formattedDate;
  };

  return (
    <Card>
      <Card.Body className="">
        <div className="d-flex justify-content-between">
          <h6 className="header-title mb-3">{title}</h6>
          <span className="text-muted">{totalMembers}</span>
        </div>
        {/* Dropdown for sorting */}
        {type === "all-miners" ? <div className="d-flex justify-content-between mb-2">
          <Dropdown>
            <Dropdown.Toggle variant="light" id="dropdown-basic">
              Sort By
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {sortOptions.map(option => (
                <Dropdown.Item
                  key={option.key}
                  active={activeSort === option.key}
                  onClick={() => setActiveSort(option.key)}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {/* show sorting by */}
          <span className="text-muted">{`Sorting by: ${sortOptions.find(option => option.key === activeSort)?.label}`}</span>
        </div> : null}
        {members.length === 0 ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner className="m-2" color="success" />
          </div>
        ) : null}
        <div className="vh-100 overflow-auto">
          {(members || []).map((item, index) => {
            const isSelected = item.email === selectedEmail;
            return (
              <div
                key={index}
                className={`d-flex mt-1 border-top pt-2 cursor-pointer ${isSelected ? "bg-light" : ""}`}
                onClick={() => handleClick(item.email)}
              >
                <div className="flex-grow-1">
                  <h5 className="mt-1 mb-0 fs-15">{item.fullName}</h5>
                  <h6 className="text-muted fw-normal mt-1 mb-2">
                    {type === "top-miners" && `Tokens: ${item.minedTokens}`}
                    {type === "top-referrers" && `Refer: ${item.totalReferrals}`}
                    {type === "all-miners" && (
                      <div className="row">
                        <div className="col">
                          {/* <FeatherIcon icon="clock" className="me-1" /> */}
                          <span>Last login: {convertToTime(item.lastLoginAt ?? 0)}</span>
                        </div>
                        <div className="col">
                          {/* <FeatherIcon icon="calendar" className="me-1" /> */}
                          <span>Created: {convertToTime(item.createdAt ?? 0)}</span>
                        </div>
                        <div className="col">
                          {/* <FeatherIcon icon="user" className="me-1" /> */}
                          <span>ID: {item.id}</span>
                        </div>
                      </div>
                    )}
                    {type === "new-miners" && (
                      <div>
                        {/* <FeatherIcon icon="calendar" className="me-1" /> */}
                        Created: {convertToTime(item.createdAt ?? 0)}
                      </div>
                    )}
                    {type === "banned-miners" && (
                      <div>
                        {/* <FeatherIcon icon="slash" className="me-1" /> */}
                        Last login: {convertToTime(item.lastLoginAt ?? 0)}
                      </div>
                    )}
                    {type === "active-miners" && (
                      <div>
                        {/* <FeatherIcon icon="activity" className="me-1" /> */}
                        Started Mining: {convertToTime(item.lastMiningStartedAt ?? 0)}
                      </div>
                    )}
                    {type === "top-shib" && (
                      <div>
                        {/* <FeatherIcon icon="dollar-sign" className="me-1" /> */}
                        SHIB: {item.totalTokens}
                      </div>
                    )}
                  </h6>
                </div>
              </div>
            );
          })}
        </div>

        <div className="d-flex justify-content-between mt-3">
          <Button
            variant="light"
            onClick={() => {
              setPageNumber(pageNumber - 1);
            }}
            disabled={pageNumber === 0}
          >
            <FeatherIcon icon="chevron-left" size={16} />
          </Button>
          <div className="d-flex align-items-center">
            <span>Size:</span>
            <Form.Control
              type="number"
              min="1"
              defaultValue="10"
              className="mx-2"
              onChange={(e) => {
                setPageSize(parseInt(e.target.value));
              }}
            />
          </div>
          <Button
            variant="light"
            onClick={() => {
              setPageNumber(pageNumber + 1);
            }}
            disabled={members.length < pageSize}
          >
            <FeatherIcon icon="chevron-right" size={16} />
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

export default MembersList;

import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { UseFormHandleSubmit, FieldErrors, Control } from "react-hook-form";

// Components
import { FormInput } from ".";

interface AddNewQuestionProps {
  newQuestionModal: boolean;
  toggleNewQuestionModal: () => void;
  handleNewQuestion: (values: any) => void;
  handleSubmit: UseFormHandleSubmit<any>;
  newQuestionDetails: any;
  errors: FieldErrors;
  control?: Control<any>;
  register?: any;
}

const AddNewQuestion: React.FC<AddNewQuestionProps> = ({
  newQuestionModal,
  toggleNewQuestionModal,
  handleNewQuestion,
  handleSubmit,
  newQuestionDetails,
  register,
  errors,
  control,
}) => {
  return (
    <Modal show={newQuestionModal} onHide={toggleNewQuestionModal} size="lg" centered>
      <Modal.Header closeButton>
        <h4 className="modal-title">Add New Question</h4>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(handleNewQuestion)} className="px-2">
          <Row className="mb-3">
            <Col>
              <FormInput
                name="question"
                label="Question"
                placeholder="Enter question"
                type="text"
                register={register}
                errors={errors}
                control={control}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <FormInput
                name="option1"
                label="Option 1"
                placeholder="Enter option 1"
                type="text"
                register={register}
                errors={errors}
                control={control}
              />
            </Col>
            <Col>
              <FormInput
                name="option2"
                label="Option 2"
                placeholder="Enter option 2"
                type="text"
                register={register}
                errors={errors}
                control={control}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <FormInput
                name="option3"
                label="Option 3"
                placeholder="Enter option 3"
                type="text"
                register={register}
                errors={errors}
                control={control}
              />
            </Col>
            <Col>
              <FormInput
                name="option4"
                label="Option 4"
                placeholder="Enter option 4"
                type="text"
                register={register}
                errors={errors}
                control={control}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <FormInput
                name="answer"
                label="Answer"
                type="select"
                register={register}
                errors={errors}
                control={control}
                defaultValue="option1"
              >
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
                <option value="option4">Option 4</option>
              </FormInput>
            </Col>
          </Row>

          <div className="text-end">
            <Button variant="light" className="me-1" onClick={toggleNewQuestionModal}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Add
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewQuestion;

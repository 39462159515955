import React, { useEffect, useState } from "react";
import { InputGroup, FormControl, DropdownButton, Dropdown, Button } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";

import WithdrawalCard from "../../../components/WithdrawalCard";
import PageTitle from "../../../components/PageTitle";
import SuccessModal from "../../../components/SuccessModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { withdrawalTypes, WithdrawalType } from "./data";

import { getWithdrawals, approveWithdrawal, rejectWithdrawal, processWithdrawal } from "../../../helpers";
import Spinner from "../../../components/Spinner";

const Withdrawals: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [withdrawals, setWithdrawals] = useState<WithdrawalType[]>([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [searchType, setSearchType] = useState<"userId" | "withdrawalId">("userId");
    const [searchQuery, setSearchQuery] = useState("");
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [confirmationCallback, setConfirmationCallback] = useState<(() => void) | null>(null);
    const [searchParams] = useSearchParams();
    const { type } = useParams<{ type: string }>();

    useEffect(() => {
        setDataLoading(true);
        const fetchData = async () => {
            const withdrawalType = withdrawalTypes.find((withdrawalType) => withdrawalType.type === type);
            const response = await getWithdrawals(withdrawalType?.api || "");
            if (withdrawalType?.type === "all") {
                setWithdrawals(response.data.result);
            } else {
                setWithdrawals(response.data.result.requests);
            }
        };
        fetchData();
        setDataLoading(false);
    }, [type, searchParams]);

    useEffect(() => {
        const params = new URLSearchParams(searchParams);
        const typeParam = params.get("type");
        if (typeParam === "withdrawalId" || typeParam === "userId") {
            setSearchType(typeParam);
        }
        const queryParam = params.get("query");
        if (queryParam) {
            setSearchQuery(queryParam);
        }
    }, [searchParams]);

    const handleApprove = async (id: string) => {
        setConfirmationMessage("Are you sure you want to approve this withdrawal?");
        setConfirmationCallback(() => () => confirmOperation(approveWithdrawal, id));
        setShowConfirmationModal(true);
    };

    const handleReject = async (id: string) => {
        setConfirmationMessage("Are you sure you want to reject this withdrawal?");
        setConfirmationCallback(() => () => confirmOperation(rejectWithdrawal, id));
        setShowConfirmationModal(true);
    };

    const handleProcess = async (id: string) => {
        setConfirmationMessage("Are you sure you want to process this withdrawal?");
        setConfirmationCallback(() => () => confirmOperation(processWithdrawal, id));
        setShowConfirmationModal(true);
    };

    const confirmOperation = async (operation: (id: string) => Promise<any>, id: string) => {
        setLoading(true);
        try {
            const response = await operation(id);
            if (response.data.statusCode === 205) {
                setSuccessMessage(response.data.result);
                setShowSuccessModal(true);
                setWithdrawals(withdrawals.map((withdrawal) => {
                    if (withdrawal.id === id) {
                        return { ...withdrawal, approved: operation === approveWithdrawal, rejected: operation === rejectWithdrawal, processedAt: operation === processWithdrawal ? Date.now() : 0 };
                    }
                    return withdrawal;
                }));
            }
        } catch (error) {
            alert(`Error ${operation === approveWithdrawal ? 'approving' : operation === rejectWithdrawal ? 'rejecting' : 'processing'} withdrawal`);
        }
        setLoading(false);
    };

    const handleSearch = () => {
        const params = new URLSearchParams();
        params.set("type", searchType);
        params.set("query", searchQuery);
        window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
    };

    const handleConfirmationCancel = () => {
        setShowConfirmationModal(false);
        setConfirmationMessage('');
        setConfirmationCallback(null);
    };

    const handleConfirmationConfirm = () => {
        if (confirmationCallback) {
            confirmationCallback();
        }
        setShowConfirmationModal(false);
        setConfirmationMessage('');
        setConfirmationCallback(null);
    };

    return (
        <React.Fragment>
            <PageTitle
                breadCrumbItems={[
                    { label: `Withdrawals`, path: `/apps/withdrawals/${type}` },
                    { label: `${withdrawalTypes.find((withdrawalType) => withdrawalType.type === type)?.title}`, path: `/withdrawals/${type}`, active: true },
                ]}
                title="Withdrawals"
            />
            {dataLoading && <div className="vh100">
                <Spinner color="green" />
            </div>}
            <div className="row justify-content-center">
                <div className="col-sm-12 col-lg-4">
                    <div className="d-flex justify-content-center align-items-center mb-3">
                        <InputGroup>
                            <DropdownButton
                                as={InputGroup.Text}
                                variant="primary"
                                title={searchType === "userId" ? "User ID" : "Withdrawal ID"}
                                id="input-group-dropdown-1"
                            >
                                <Dropdown.Item onClick={() => setSearchType("userId")}>User ID</Dropdown.Item>
                                <Dropdown.Item onClick={() => setSearchType("withdrawalId")}>Withdrawal ID</Dropdown.Item>
                            </DropdownButton>
                            <FormControl
                                placeholder={`Search ${searchType === "userId" ? "User ID" : "Withdrawal ID"}`}
                                aria-label="Search"
                                aria-describedby="search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            
                            <Button variant="success" onClick={handleSearch}>Search</Button>
                        </InputGroup>
                    </div>
                </div>
            </div>
            <div className="row">
                {withdrawals
                    .filter((withdrawal) =>
                        searchType === "userId"
                            ? withdrawal.userId.toLowerCase().includes(searchQuery.toLowerCase())
                            : withdrawal.id.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                    .map((withdrawal) => (
                        <div key={withdrawal.id} className="col-sm-12 col-lg-6">
                            <WithdrawalCard
                                loading={loading}
                                data={withdrawal}
                                handleApprove={handleApprove}
                                handleReject={handleReject}
                                handleProcess={handleProcess}
                            />
                        </div>
                    ))}
            </div>
            {showConfirmationModal && <ConfirmationModal
                show={showConfirmationModal}
                message={confirmationMessage}
                onHide={handleConfirmationCancel}
                onConfirm={handleConfirmationConfirm}
            />}
            {showSuccessModal && <SuccessModal
                show={showSuccessModal}
                message={successMessage}
                onHide={() => setShowSuccessModal(false)}
            />}
        </React.Fragment>
    );
}

export default Withdrawals;

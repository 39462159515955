import { APICore } from "./apiCore";

import { SettingsType, AppRatesType } from '../../pages/apps/Settings/data';
const api = new APICore();

export async function getAppRates() {
    const baseUrl = "/admin/appRates";
    const data = await api.get(`${baseUrl}`, {});
    return data;
}

export async function getSettings() {
    const baseUrl = "/admin/settings";
    const data = await api.get(`${baseUrl}`, {});
    return data;
}

export async function updateSettings(settings: SettingsType) {
    const baseUrl = "/admin/modifySettings";
    const data = await api.create(`${baseUrl}`, settings);
    return data;
}

export async function updateAppRates(appRates: AppRatesType) {
    const baseUrl = "/admin/modifyAppRates";
    const data = await api.create(`${baseUrl}`, appRates);
    return data;
}

export function getVersion() {
    const baseUrl = "/admin/app";
    const data = api.get(`${baseUrl}`, {});
    return data;
}

export function modifyVersion(version: any) {
    const baseUrl = "/admin/modifyApp";
    const data = api.create(`${baseUrl}`, version);
    return data;
}
import React, { useEffect, useState } from "react";
import { Card, Dropdown, Row, Col, Button, Badge } from "react-bootstrap";
import Spinner from "../Spinner"
import { getUser, getUserById, updateUser, deleteUser, resetPassword } from "../../helpers";
import ConfirmationModal from "../ConfirmationModal";
import ResetPassModal from "../ResetPassModal";
import SuccessModal from "../SuccessModal";
import ReferralList from "./ReferralList";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
/**
    "result": {
        "id": "6638eaa5cfe67f1a0ffa7ffd",
        "aciTokens": 0.0,
        "minedTokens": 0.0,
        "referralTokens": 0.0,
        "referralMinedBonusTokens": 0.0,
        "quizRewardTokens": 0.0,
        "shibTokens": 2.0,
        "totalMiningHours": 0,
        "totalMiningCount": 0,
        "totalMiningSeasonCount": 0,
        "lifeTimeHours": 0,
        "isKycVerified": 2,
        "kycState": 0,
        "email": "nsu.turag@gmail.com",
        "password": null,
        "role": "Admin",
        "fullName": "Admin 1",
        "phoneNumber": "01711111110",
        "isDisabled": 2,
        "emailVerified": 1,
        "phoneVerified": 2,
        "canNotify": 2,
        "fcmToken": null,
        "totalQuestionsSolved": 0,
        "totalSolvedQuizzes": 0,
        "score": 0,
        "totalQuestionSolvedInSeason": 0,
        "totalSeasonFinished": 0,
        "createdAt": 1715006117029,
        "lastLoginAt": 1715102565740,
        "lastMiningAt": 0,
        "lastQuizAt": 0,
        "updatedAt": 1715006117029
    },
 */
interface UserDetails {
    id: string;
    aciTokens: number;
    minedTokens: number;
    referralTokens: number;
    referralMinedBonusTokens: number;
    quizRewardTokens: number;
    shibTokens: number;
    totalMiningHours: number;
    totalMiningCount: number;
    totalMiningSeasonCount: number;
    lifeTimeHours: number;
    isKycVerified: number;
    kycState: number;
    email: string;
    role: string;
    fullName: string;
    phoneNumber: string;
    isDisabled: number;
    emailVerified: number;
    phoneVerified: number;
    canNotify: number;
    totalQuestionsSolved: number;
    totalSolvedQuizzes: number;
    score: number;
    totalQuestionSolvedInSeason: number;
    totalSeasonFinished: number;
    createdAt: number;
    lastLoginAt: number;
    lastMiningAt: number;
    lastQuizAt: number;
    updatedAt: number;
    password?: string;
    fcmToken?: string;
}
interface UserProfileProps {
    email?: string;
    userId?: string;
}

const UserProfile: React.FC<UserProfileProps> = ({ email, userId }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [userDetails, setUserDetails] = useState<UserDetails>({} as UserDetails)
    const [editedUserDetails, setEditedUserDetails] = useState<UserDetails>({} as UserDetails);
    const [loading, setLoading] = useState<boolean>(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)
    const [userNotFound, setUserNotFound] = useState<boolean>(false)

    const [resetPassModal, setResetPassModal] = useState<boolean>(false);
    const handleResetPasswordClick = () => {
        setResetPassModal(true);
    };
    const toggleResetPassModal = () => {
        reset()
        setResetPassModal(!resetPassModal);
    };
    const onResetPassConfirm = async (values: any) => {
        console.log('onResetPassConfirm:', values);
        try {
            const response = await resetPassword(userDetails.email, values.password);
            if (response.data.statusCode === 200) {
                setResetPassModal(false);
                setShowSuccessModal(true);
            } else {
                throw new Error("Error resetting password");
            }
        } catch (error) {
            console.error('Error resetting password:', error);
            alert('Error resetting password');
            // Handle error state or display error message to the user
        }
    };
    

    const formatedDate = (date: number) => {
        return date === 0 ? "N/A" : new Date(date).toLocaleString();
    }
    // Define the password regex pattern
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    const schemaResolver = yupResolver(
        yup.object().shape({
            password: yup
                .string()
                .required("Password is required")
                .matches(passwordRegex, "Password must contain uppercase, lowercase, number, and be at least 8 characters"),
            confirmPassword: yup
                .string()
                .required("Confirm Password is required")
                .oneOf([yup.ref('password'), null], "Passwords must match"),
        })
    );
    const methods = useForm({ resolver: schemaResolver });
    const {
        handleSubmit,
        register,
        control,
        reset,
        formState: { errors },
    } = methods;
    useEffect(() => {
        setLoading(true);
        setUserDetails({} as UserDetails);
        setEditedUserDetails({} as UserDetails);

        async function getData() {
            try {
                let response;
                if (userId === "") {
                    response = await getUser({ email: email || "" });
                } else {
                    response = await getUserById(userId ?? "");
                }
                setUserDetails(response.data.result);
                setEditedUserDetails(response.data.result);
                setLoading(false);
                setUserNotFound(false);
            } catch (err) {
                console.log(err);
                setLoading(false);
                setUserDetails({} as UserDetails);
                setUserNotFound(true);
            }
        }
        getData();
    }, [email, userId]);
    /**
     
interface ResetPassModalProps {
    resetPassModal: boolean;
    toggleResetPassModal: () => void;
    handleResetPassword: (values: any) => void;
    handleSubmit: UseFormHandleSubmit<any>;
    errors: FieldErrors;
    control?: Control<any>;
    register?: any;
}
     */
    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        setShowConfirmationModal(true)
    };

    const handleDeleteClick = () => {
        setShowConfirmationModal(true)
    };

    const handleEditConfirmation = async () => {
        // console.log('editedUserDetails:', editedUserDetails);
        // remove password and fcmToken from editedUserDetails
        delete editedUserDetails.password;
        delete editedUserDetails.fcmToken;
        try {
            const response = await updateUser(editedUserDetails);
            if (response.data.statusCode === 202) {
                setUserDetails(editedUserDetails);
                setShowConfirmationModal(false);
                setIsEditing(false);
                setShowSuccessModal(true);
            } else {
                throw new Error("Error updating user details");
            }
        } catch (error) {
            console.error('Error updating user details:', error);
            alert('Error updating user details');
            // Handle error state or display error message to the user
        }
    };

    const handleDeleteConfirmation = async () => {
        try {
            const response = await deleteUser(userDetails.email);
            if (response.data.statusCode === 205) {
                setShowConfirmationModal(false);
                setShowSuccessModal(true);
                setTimeout(() => {
                    //refresh the page
                    window.location.reload();
                }, 2000)
            } else {
                throw new Error("Error deleting user");
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            alert('Error deleting user');
            // Handle error state or display error message to the user
        }
    };
    const handleCancelClick = () => {
        setIsEditing(false);
        setEditedUserDetails({ ...userDetails })
    }
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditedUserDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const hideModal = () => {
        setShowConfirmationModal(false)
    }
    const renderConfirmationModal = () => {
        return (
            <ConfirmationModal
                message="Are you sure you want to edit this user?"
                show={showConfirmationModal}
                onHide={hideModal}
                onConfirm={isEditing ? handleEditConfirmation : handleDeleteConfirmation}
            />
        );
    };
    if (userNotFound && (email || userId)) {
        return (
            <Card>
                <Card.Body>
                    <h4>User not found</h4>
                </Card.Body>
            </Card>
        );
    }
    return (
        <React.Fragment>
            {
                userDetails.id &&
                <Card>
                    <Card.Body>
                        {
                            loading ?
                                <div className="w-100 vh-100 align-items-center d-flex justify-content-center">
                                    <Spinner className=" m-2" color="success" />
                                </div>
                                : null
                        }
                        <div className="d-flex">
                            {/* <img src={avatar} className="avatar-lg rounded-circle me-2" alt="" /> */}
                            <div className="flex-grow-1">
                                <div className="d-flex">
                                <h4 className="mt-2 mb-0">{userDetails.fullName} : </h4>
                                <h4 className="mt-2 mb-0 text-info">{userDetails.id} <i  className="uil uil-copy ms-2 cursor-pointer" onClick={() => navigator.clipboard.writeText(userDetails.id)}></i></h4>
                                </div>
                                <h4 className="text-muted fw-normal mt-1 mb-2">Total token: {userDetails.minedTokens + userDetails.referralTokens + userDetails.referralMinedBonusTokens + userDetails.quizRewardTokens}</h4>
                                {/* <h6 className="text-muted fw-normal mt-1 mb-4">{userDetails.country}</h6> */}
                            </div>
                            <Dropdown className="float-end" align="end">
                                <Dropdown.Toggle
                                    as="a"
                                    className="cursor-pointer arrow-none text-muted"
                                >
                                    <i className="uil uil-ellipsis-v"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={handleEditClick}>
                                        <i className="uil uil-edit-alt me-2"></i>Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={handleResetPasswordClick}>
                                        <i className="uil uil-refresh me-2"></i>Reset Password
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item className="text-danger" onClick={handleDeleteClick} >
                                        <i className="uil uil-trash me-2"></i>Delete
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <section className="row">

                            <div className="my-2 col-md-4">
                                <h5>Full Name</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="fullName"
                                            value={editedUserDetails.fullName ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Full Name" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.fullName}</p>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Email</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="email"
                                            value={editedUserDetails.email ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Email" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.email} <i className="uil uil-copy ms-2" onClick={() => navigator.clipboard.writeText(userDetails.email)}></i></p>

                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Phone Number</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="phoneNumber"
                                            value={editedUserDetails.phoneNumber ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Phone Number" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.phoneNumber}</p>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Role</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="role"
                                            value={editedUserDetails.role ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Role" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.role}</p>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>ACI Tokens</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="aciTokens"
                                            value={editedUserDetails.aciTokens ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="ACI Tokens" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.aciTokens}</p>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Mined Tokens</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="minedTokens"
                                            value={editedUserDetails.minedTokens ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Mined Tokens" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.minedTokens}</p>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Referral Tokens</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="referralTokens"
                                            value={editedUserDetails.referralTokens ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Referral Tokens" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.referralTokens}</p>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Referral Mined Bonus Tokens</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="referralMinedBonusTokens"
                                            value={editedUserDetails.referralMinedBonusTokens ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Referral Mined Bonus Tokens" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.referralMinedBonusTokens}</p>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Quiz Reward Tokens</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="quizRewardTokens"
                                            value={editedUserDetails.quizRewardTokens ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Quiz Reward Tokens" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.quizRewardTokens}</p>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Shib Tokens</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="shibTokens"
                                            value={editedUserDetails.shibTokens ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Shib Tokens" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.shibTokens}</p>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Total Mining Hours</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="totalMiningHours"
                                            value={editedUserDetails.totalMiningHours ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Total Mining Hours" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.totalMiningHours}</p>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Total Mining Count</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="totalMiningCount"
                                            value={editedUserDetails.totalMiningCount ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Total Mining Count" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.totalMiningCount}</p>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Total Mining Season Count</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="totalMiningSeasonCount"
                                            value={editedUserDetails.totalMiningSeasonCount ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Total Mining Season Count" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.totalMiningSeasonCount}</p>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Is KYC Verified</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="isKycVerified"
                                            value={editedUserDetails.isKycVerified ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Is KYC Verified"
                                        />
                                        :
                                        <h6>
                                            {userDetails.isKycVerified === 2 ? (
                                                // <span className="badge badge-danger">Not Verified</span>
                                                <Badge bg="danger">Not Verified</Badge>
                                            ) : userDetails.isKycVerified === 1 ? (
                                                // <span className="badge badge-success">Verified</span>
                                                <Badge bg="success">Verified</Badge>
                                            ) : (
                                                // <span className="badge badge-secondary">Undefined</span>
                                                <Badge bg="secondary">Undefined</Badge>
                                            )}
                                        </h6>

                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>KYC State</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="kycState"
                                            value={editedUserDetails.kycState ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="KYC State" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.kycState}</p>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Email Verified</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="emailVerified"
                                            value={editedUserDetails.emailVerified ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Email Verified" // Add the title attribute for the label
                                        />
                                        :
                                        // <p>{userDetails.emailVerified}</p>
                                        <h6>
                                            {userDetails.emailVerified === 2 ? (
                                                // <span className="badge badge-danger">Not Verified</span>
                                                <Badge bg="danger">Not Verified</Badge>
                                            ) : userDetails.emailVerified === 1 ? (
                                                // <span className="badge badge-success">Verified</span>
                                                <Badge bg="success">Verified</Badge>
                                            ) : (
                                                // <span className="badge badge-secondary">Undefined</span>
                                                <Badge bg="secondary">Undefined</Badge>
                                            )}
                                        </h6>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Phone Verified</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="phoneVerified"
                                            value={editedUserDetails.phoneVerified ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Phone Verified" // Add the title attribute for the label
                                        />
                                        :
                                        // <p>{userDetails.phoneVerified}</p>
                                        <h6>
                                            {userDetails.phoneVerified === 2 ? (
                                                // <span className="badge badge-danger">Not Verified</span>
                                                <Badge bg="danger">Not Verified</Badge>
                                            ) : userDetails.phoneVerified === 1 ? (
                                                // <span className="badge badge-success">Verified</span>
                                                <Badge bg="success">Verified</Badge>
                                            ) : (
                                                // <span className="badge badge-secondary">Undefined</span>
                                                <Badge bg="secondary">Undefined</Badge>
                                            )}
                                        </h6>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Can Notify</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="canNotify"
                                            value={editedUserDetails.canNotify ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Can Notify" // Add the title attribute for the label
                                        />
                                        :
                                        // <p>{userDetails.canNotify}</p>
                                        <h6>
                                            {userDetails.canNotify === 2 ? (
                                                // <span className="badge badge-danger">Not Verified</span>
                                                <Badge bg="danger">NO</Badge>
                                            ) : userDetails.canNotify === 1 ? (
                                                // <span className="badge badge-success">Verified</span>
                                                <Badge bg="success">YES</Badge>
                                            ) : (
                                                // <span className="badge badge-secondary">Undefined</span>
                                                <Badge bg="secondary">Undefined</Badge>
                                            )}
                                        </h6>
                                }
                            </div>

                            <div className="my-2 col-md-4">
                                <h5>Total Questions Solved</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="totalQuestionsSolved"
                                            value={editedUserDetails.totalQuestionsSolved ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Total Questions Solved" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.totalQuestionsSolved}</p>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Total Solved Quizzes</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="totalSolvedQuizzes"
                                            value={editedUserDetails.totalSolvedQuizzes ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Total Solved Quizzes" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.totalSolvedQuizzes}</p>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Score</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="score"
                                            value={editedUserDetails.score ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Score" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.score}</p>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Total Question Solved In Season</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="totalQuestionSolvedInSeason"
                                            value={editedUserDetails.totalQuestionSolvedInSeason ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Total Question Solved In Season" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.totalQuestionSolvedInSeason}</p>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Total Season Finished</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="totalSeasonFinished"
                                            value={editedUserDetails.totalSeasonFinished ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Total Season Finished" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.totalSeasonFinished}</p>
                                }
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Is Disabled</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="isDisabled"
                                            value={editedUserDetails.isDisabled ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Is Disabled" // Add the title attribute for the label
                                        />
                                        :
                                        // <p>{userDetails.isDisabled}</p>
                                        <h6>
                                            {userDetails.isDisabled === 2 ? (
                                                // <span className="badge badge-danger">Not Verified</span>
                                                <Badge bg="success">NO</Badge>
                                            ) : userDetails.isDisabled === 1 ? (
                                                // <span className="badge badge-success">Verified</span>
                                                <Badge bg="danger">YES</Badge>
                                            ) : (
                                                // <span className="badge badge-secondary">Undefined</span>
                                                <Badge bg="secondary">Undefined</Badge>
                                            )}
                                        </h6>
                                }
                            </div>

                            <div className="my-2 col-md-4">
                                <h5>Created At</h5>
                                <p>{formatedDate(userDetails.createdAt)}</p>
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Updated At</h5>
                                <p>{formatedDate(userDetails.updatedAt)}</p>
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Last Login At</h5>
                                <p>{formatedDate(userDetails.lastLoginAt)}</p>
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Last Mining At</h5>
                                <p>{formatedDate(userDetails.lastMiningAt)}</p>
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Last Quiz At</h5>
                                <p>{formatedDate(userDetails.lastQuizAt)}</p>
                            </div>
                            <div className="my-2 col-md-4">
                                <h5>Life Time Hours</h5>
                                {
                                    isEditing ?
                                        <input
                                            type="text"
                                            name="lifeTimeHours"
                                            value={editedUserDetails.lifeTimeHours ?? ''}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            title="Life Time Hours" // Add the title attribute for the label
                                        />
                                        :
                                        <p>{userDetails.lifeTimeHours}</p>
                                }
                            </div>

                        </section>
                        {isEditing && (
                            <Row>
                                <Col sm={1}>
                                    <Button onClick={handleSaveClick}>Save</Button>
                                </Col>
                                <Col sm={1}>
                                    <Button variant="warning" onClick={handleCancelClick}>Cancel</Button>
                                </Col>
                            </Row>
                        )}
                        <ReferralList email={userDetails.email} />
                    </Card.Body>
                </Card>}
            {showConfirmationModal && renderConfirmationModal()}
            {showSuccessModal && <SuccessModal message="User details updated successfully" onHide={() => setShowSuccessModal(false)} show={showSuccessModal} />}
            <ResetPassModal
                resetPassModal={resetPassModal}
                toggleResetPassModal={toggleResetPassModal}
                handleResetPassword={handleSubmit(onResetPassConfirm)}
                handleSubmit={handleSubmit}
                errors={errors}
                control={control}
                register={register}
            />
        </React.Fragment>
    );
};

export default UserProfile;

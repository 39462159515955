import React, { useState, useEffect } from 'react';

import Spinner from '../../../components/Spinner';
import ConfirmationModal from '../../../components/ConfirmationModal';
import SuccessModal from '../../../components/SuccessModal';
import PageTitle from '../../../components/PageTitle';
import SettingCard from '../../../components/SettingsCard';
import { SettingsType, AppRatesType, AppType } from './data';
import { getSettings, updateSettings, getAppRates, updateAppRates, getVersion, modifyVersion } from '../../../helpers';
import AppVersion from '../../../components/AppVersion';

const Settings: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState<SettingsType>({} as SettingsType);
    const [appRates, setAppRates] = useState<AppRatesType>({} as AppRatesType);
    const [appVersion, setAppVersion] = useState<AppType>({} as AppType)
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [confirmationCallback, setConfirmationCallback] = useState<(() => void) | null>(null);
    const fetchData = async () => {
        const settingsResponse = await getSettings();
        const appRatesResponse = await getAppRates();
        const versionResponse = await getVersion();
        setSettings(settingsResponse.data.result);
        setAppRates(appRatesResponse.data.result);
        setAppVersion(versionResponse.data.result);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, []);

    const handleSettingsChange = (newSettings: SettingsType) => {
        setConfirmationMessage('Are you sure you want to update the settings?');
        setConfirmationCallback(() => () => {
            setSettings(newSettings);
            saveSettings(newSettings);
        });
        setShowConfirmationModal(true);
    }

    const handleAppRatesChange = (newAppRates: AppRatesType) => {
        setConfirmationMessage('Are you sure you want to update the app rates?');
        setShowConfirmationModal(true);
        setConfirmationCallback(() => () => {
            setAppRates(newAppRates);
            saveAppRates(newAppRates);
        });

    }

    const handleVersionChange = (data: AppType) => {
        setConfirmationMessage('Are you sure you want to update the app version?');
        setShowConfirmationModal(true);
        setConfirmationCallback(() => () => {
            setAppVersion(data);
            saveVersion(data);
        });
    }
    const saveSettings = async (newSettings: SettingsType) => {
        const response = await updateSettings(newSettings);
        if (response.data.statusCode === 200) {
            setSuccessMessage('Settings have been updated successfully.');
            setShowSuccessModal(true);
        }
    }

    const saveAppRates = async (newAppRates: AppRatesType) => {
        const response = await updateAppRates(newAppRates);
        if (response.data.statusCode === 200) {
            setSuccessMessage('App rates have been updated successfully.');
            setShowSuccessModal(true);
        }
    }

    const saveVersion = async (data: AppType) => {
        const response = await modifyVersion(data);
        if (response.data.statusCode === 200) {
            setSuccessMessage('App version has been updated successfully.');
            setShowSuccessModal(true);
        }
    }
    const handleModalConfirm = () => {
        setShowConfirmationModal(false);
        if (confirmationCallback) {
            confirmationCallback();
        }
    }
    return (
        <React.Fragment>
            <PageTitle
                breadCrumbItems={[
                    { label: 'Apps', path: '/apps/settings' },
                    { label: 'Settings', path: '/apps/settings', active: true }
                ]}
                title="Settings"
            />
            {loading ? <Spinner color='success' /> :
                <React.Fragment>
                    <SettingCard
                        title="Settings"
                        data={
                            [
                                { label: 'Mine Season Hours', value: settings.mineSeasonHours, key: 'mineSeasonHours' },
                                { label: 'Total Quiz', value: settings.totalQuizInSeason, key: 'totalQuizInSeason' },
                                { label: 'Mining Cycle In Seconds', value: settings.miningCycleInSeconds, key: 'miningCycleInSeconds' },
                                { label: 'Quiz Cycle In Seconds', value: settings.quizCycleInSeconds, key: 'quizCycleInSeconds' }
                            ]
                        }
                        onDataChange={handleSettingsChange}
                    />
                    <SettingCard
                        title="App Rates"
                        data={
                            [
                                { label: 'Hash Rate', value: appRates.hashRate, key: 'hashRate' },
                                { label: 'Referral Join Aware Rate', value: appRates.referralJoinAwareRate, key: 'referralJoinAwareRate' },
                                { label: 'Referral Mining Percentage', value: appRates.referralMiningPercentage, key: 'referralMiningPercentage' },
                                { label: 'Quiz Collection Award Rate', value: appRates.quizCollectionAwardRate, key: 'quizCollectionAwardRate' },
                                { label: 'Shib Tokens Reward Amount On Mine Session Completion', value: appRates.shibTokensRewardAmountOnMineSessionCompletion, key: 'shibTokensRewardAmountOnMineSessionCompletion' },
                                { label: 'Shib Tokens Reward Amount On Quiz Session Completion', value: appRates.shibTokensRewardAmountOnQuizSessionCompletion, key: 'shibTokensRewardAmountOnQuizSessionCompletion' },
                                { label: 'Min Shib Token Withdrawal Amount', value: appRates.minShibTokenWithdrawalAmount, key: 'minShibTokenWithdrawalAmount' }
                            ]
                        }
                        onDataChange={handleAppRatesChange}
                    />
                    {/* interface AppVersionProps {
    app: AppType;
    onCancel: () => void;
    onSave: (data: AppType) => void;
} */}
                    <AppVersion app={appVersion} onSave={handleVersionChange} />
                </React.Fragment>
            }
            <ConfirmationModal show={showConfirmationModal} message={confirmationMessage}
                onHide={() => {
                    setShowConfirmationModal(false)
                    setConfirmationCallback(null);
                    fetchData();
                }}
                onConfirm={handleModalConfirm} />
            <SuccessModal show={showSuccessModal} message={successMessage} onHide={() => setShowSuccessModal(false)} />
        </React.Fragment>
    );
}

export default Settings;

import React from "react";
import { Modal, Button } from "react-bootstrap";

interface SuccessModalProps {
    show: boolean;
    message: string;
    onHide: () => void;
}
const SuccessIcon = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 130.2 130.2"
        >
            <circle
                className="path circle"
                fill="none"
                stroke="#4bd396"
                strokeWidth="6"
                strokeMiterlimit="10"
                cx="65.1"
                cy="65.1"
                r="62.1"
            />
            <polyline
                className="path check"
                fill="none"
                stroke="#4bd396"
                strokeWidth="6"
                strokeLinecap="round"
                strokeMiterlimit="10"
                points="100.2,40.2 51.5,88.8 29.8,67.5 "
            />
        </svg>
    );
};
const SuccessModal: React.FC<SuccessModalProps> = ({ show, message, onHide }) => {

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Body className="text-center">
                <div className="logout-checkmark">
                    <SuccessIcon />
                </div>

                <h4 className="mt-3">{message}</h4>
                <div className="mt-3">
                    <Button variant="primary"
                        onClick={onHide}
                    >Ok</Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SuccessModal;
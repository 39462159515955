
export interface WithdrawalType {
  id: string;
  userId: string;
  walletAddress: string;
  walletNetwork: string;
  binanceId: string;
  coinType: string;
  amount: number;
  createdAt: number;
  processedAt: number;
  updatedAt: number;
  rejected: boolean;
  approved: boolean;
}

const withdrawalTypes = [
    {
        type: "all",
        api: "requests",
        title: "All Withdrawals",
    },
    {
        type: "pending",
        api: "pending",
        title: "Pending Withdrawals",
    },
    {
        type: "accepted",
        api: "accepted",
        title: "Approved Withdrawals",
    },
    {
        type: "rejected",
        api: "rejected",
        title: "Rejected Withdrawals",
    },
    ] as const;

export { withdrawalTypes }
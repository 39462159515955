
interface userListType {
    fullName: string,
    userId: string,
    email: string,
    id?: string,
    minedTokens?: number,
    totalReferrals?: number,
    createdAt?: number,
    lastLoginAt?: number,
    lastMiningStartedAt?: number,
    totalTokens?: number,
}
const UserTypes = [
    {
        type: "top-miners",
        api: "topMiners",
        countApi: "",
        title: "Top Miners",
    },
    {
        type: "top-referrers",
        api: "topReferrers",
        countApi: "",
        title: "Top Referrers",
    },
    
    {
        type: "all-miners",
        api: "all",
        countApi: "users/total",
        title: "All Miners",
    },
    {
        type: "active-miners",
        api: "activeMiningUsers",
        countApi: "users/activeMiners",
        title: "Active Miners",
    },
    {
        type: "inactive-miners",
        api: "",
        countApi: "users/inActiveMiners",
        title: "Inactive Miners",
    },
    {
        type: "new-miners",
        api: "getNewUsers",
        countApi: "",
        title: "Newly Registered Miners",
    },
    {
        type: "uninstalled-miners",
        api: "",
        countApi: "",
        title: "Uninstalled Miners",
    },
    {
        type: "banned-miners",
        api: "bannedUsers",
        countApi: "users/totalBannedMiners",
        title: "Banned Miners",
    },
    {
        type: "top-shib",
        api: "topHoldingShibTokens",
        countApi: "users/holdingShibTokens",
        title: "Top SHIB Holders",
    },
] as const;

export { UserTypes }
export type { userListType }
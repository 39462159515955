import { postQuestion } from "../../../helpers";
import { APICore } from "../../../helpers/api/apiCore";

interface QuestionType {
    question: string,
    answer: string,
    option1: string,
    option2: string,
    option3: string,
    option4: string,
}

const api = new APICore();
let questions: QuestionType[] = []; // Renamed from Questions to follow convention

async function updateQuestions() {
    try {
        const response = await api.get("admin/question/all", {});
        questions = response.data.result;
    } catch (error) {
        console.error("Error fetching questions:", error);
    }
}

updateQuestions();

export { questions }; export type { QuestionType };


import React, { useEffect, useState } from 'react';
import Pagination from '../Pagination';
import { Badge } from 'react-bootstrap';

import { getRefList } from '../../helpers';
interface DataType {
    fullName: string;
    isMining: boolean;
}

interface Props {
    email: string;
}

const ReferralList: React.FC<Props> = ({ email }) => {

    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(30);
    const [totalRefererCount, setTotalRefererCount] = useState<number>(0);
    const [currentData, setCurrentData] = useState<DataType[]>([]);
    const [data, setData] = useState<DataType[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getRefList(email);
                setData(response.data.result);
                setTotalRefererCount(response.data.result.length);
            } catch (error) {
                console.error("Error fetching referral list:", error);
            }
        };
        fetchData();
    }, [email]);
    useEffect(() => {
        const startIndex = pageIndex * pageSize;
        const endIndex = startIndex + pageSize;
        setCurrentData(data.slice(startIndex, endIndex));
    }, [pageIndex, pageSize, data]);

    return (
        <div>
            {/* <h2 className='mt-3 mb-1'>Referral List</h2> */}
            <div className='mb-3 d-flex justify-content-between'>
                <h2 className='mb-1'>Referral List</h2>
                <h3 className='mb-1'>
                    Total Referrals: <Badge bg='primary'>{totalRefererCount}</Badge>
                </h3>
            </div>
            <div className='mb-3 d-flex flex-wrap'>
                {currentData.map((item, index) => (
                    <h3 key={index} className='me-1 mb-1'>
                        <Badge bg={item.isMining ? 'success' : 'danger'}>
                            {item.fullName}
                        </Badge>
                    </h3>
                ))}
            </div>

            <Pagination
                tableProps={{
                    pageCount: Math.ceil(data.length / pageSize),
                    state: { pageIndex },
                    gotoPage: (page: number) => {
                        setPageIndex(page);
                    },
                    pageOptions: Array.from({ length: Math.ceil(data.length / pageSize) }).map((_, i) => i),
                }}
                sizePerPageList={[]}
            />
        </div>
    );
};

export default ReferralList;
import { APICore } from "./apiCore";
const api = new APICore();

function getWithdrawals(endpoint: string) {
    const baseUrl = `/admin/withdraw/${endpoint}`;
    const data = api.get(`${baseUrl}`, {});
    return data;
}

function approveWithdrawal(id: string) {
    const baseUrl = "/admin/withdraw/approve";
    const params = { id };
    const data = api.get(`${baseUrl}`, params);
    return data;
}

function rejectWithdrawal(id: string) {
    const baseUrl = "/admin/withdraw/reject";
    const params = { id };
    const data = api.get(`${baseUrl}`, params);
    return data;
}

function processWithdrawal(id: string) {
    const baseUrl = "/admin/withdraw/process";
    const params = { id };
    const data = api.get(`${baseUrl}`, params);
    return data;
}

export { getWithdrawals, approveWithdrawal, rejectWithdrawal, processWithdrawal }

import React, { useEffect, useState } from "react";
import { Card, Button, Badge, Row, Col } from "react-bootstrap";
import { WithdrawalType } from "../pages/apps/Withdrawals/data";
import { Link } from "react-router-dom";
interface Props {
  data: WithdrawalType;
  loading: boolean;
  handleApprove: (id: string) => void;
  handleReject: (id: string) => void;
  handleProcess: (id: string) => void;
}

const WithdrawalCard: React.FC<Props> = ({ data, handleApprove, handleReject, handleProcess, loading }) => {
  const [processedStatus, setProcessedStatus] = useState(false);
  const [statusText, setStatusText] = useState('');
  const [statusVariant, setStatusVariant] = useState('');
  const [formattedCreatedAt, setFormattedCreatedAt] = useState('');
  const [formattedProcessedAt, setFormattedProcessedAt] = useState('');
  const [formattedUpdatedAt, setFormattedUpdatedAt] = useState('');

  useEffect(() => {
    setProcessedStatus(data.processedAt === 0 ? false : true);
    setStatusText(processedStatus ? 'Processed' : data.approved ? 'Approved' : data.rejected ? 'Rejected' : 'Pending');
    setStatusVariant(processedStatus ? 'primary' : data.approved ? 'success' : data.rejected ? 'danger' : 'warning');
    setFormattedCreatedAt(new Date(data.createdAt).toLocaleString());
    setFormattedProcessedAt(data.processedAt ? new Date(data.processedAt).toLocaleString() : 'Not processed yet');
    setFormattedUpdatedAt(new Date(data.updatedAt).toLocaleString());
  }, [data, processedStatus]);

  return (
    <Card className="mb-3">
      <Card.Body>
        <Row className="mb-3">
          <Col xs={12}>
            <Card.Title>
              User:
              <Link to={`/apps/users/search?userId=${data.userId}`}>
                {` ${data.userId}`}
              </Link>
              <i
                onClick={() => navigator.clipboard.writeText(data.userId)}
                className="uil uil-copy"></i>
              <div className="mt-1">
                <strong>ID:</strong> {data.id} <i
                  onClick={() => navigator.clipboard.writeText(data.id)}
                  className="uil uil-copy"></i>
              </div>
              {/* ID: {data.id}  */}
            </Card.Title>
            <h2>
              <Badge bg={statusVariant} className="float-md-end">{statusText}</Badge>
            </h2>

            <Card.Subtitle className="mb-2 text-muted">Coin Type: {data.coinType}</Card.Subtitle>
            <Card.Text>
              <strong>Amount:</strong> {data.amount} {data.coinType} <i
                onClick={() => navigator.clipboard.writeText(data.amount.toString())}
                className="uil uil-copy"></i>
            </Card.Text>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Card.Text
            >
              <strong>Wallet Address:</strong> {data.walletAddress}
              <i
                onClick={() => navigator.clipboard.writeText(data.walletAddress)}
                className="uil uil-copy"></i>
            </Card.Text>
            <Card.Text>
              <strong>Wallet Network:</strong> {data.walletNetwork} <i
                onClick={() => navigator.clipboard.writeText(data.walletNetwork)}
                className="uil uil-copy"></i>
            </Card.Text>
            <Card.Text>
              <strong>Binance ID:</strong> {data.binanceId} <i
                onClick={() => navigator.clipboard.writeText(data.binanceId)}
                className="uil uil-copy"></i>
            </Card.Text>
          </Col>
          <Col xs={12} md={6}>
            <Card.Text className="mt-2 mt-md-0">
              <strong>Created At:</strong> {formattedCreatedAt}
            </Card.Text>
            <Card.Text>
              <strong>Processed At:</strong> {formattedProcessedAt}
            </Card.Text>
            <Card.Text>
              <strong>Updated At:</strong> {formattedUpdatedAt}
            </Card.Text>
          </Col>
        </Row>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-3">
          {
            !data.rejected && !processedStatus && !data.approved && (
              <Button variant="danger" onClick={() => handleReject(data.id)} disabled={loading}>Reject</Button>
            )
          }
          {
            !data.approved && !processedStatus && !data.rejected && (
              <Button variant="primary" onClick={() => handleProcess(data.id)} disabled={loading}>Process</Button>
            )
          }
          {
            !data.approved && processedStatus && !data.rejected && (
              <Button variant="success" onClick={() => handleApprove(data.id)} disabled={loading}>Approve</Button>
            )
          }
        </div>
      </Card.Body>
    </Card>
  );
};

export default WithdrawalCard;
